<template>
    <!--FORMULAR-->
    <section class="pa-4 px-2 formular career">
        <!--SAVE BUTTON TOOLBAR-->
        <div class="toolbar-buttons">
            <v-btn v-if="checkPrivileg && edit" dark small depressed text @click="submit('saveItem')">
                <v-icon left>mdi-content-save-outline</v-icon>
                <span>{{ $t('common.save') }}</span>
            </v-btn>
        </div>
        <AdvancedMenu :items="advancedMenu" :handle="handleClick" v-if="checkPrivileg && edit"/>

        <!--CAREER DATA-->
        <v-container fluid>
            <CareerWidget v-if="data_ready && this.edit" :seller_data="getItem" :can_see="canSeeCareer"/>
        </v-container>

        <!--FORMS-->
        <v-container v-if="checkPrivileg" fluid>
            <v-form @submit.prevent="submit" id="form" ref="form" class="formular">
                <!--BASIC DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.basic_data') }}
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default" v-if="edit">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.id')"
                                              v-model="seller_data.id"
                                              disabled
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-select outlined dense color="gold"
                                          :label="$t('users.dialog.type')"
                                          item-text="name"
                                          item-value="id"
                                          :items="person_types"
                                          v-model="seller_data.type"
                                          @change="checkType()"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.title')"
                                              v-model="seller_data.degree"
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.name')"
                                              v-model="registration_data.name"
                                              @keyup="generateEmail"
                                              autocomplete="off"
                                              v-validate="'required'"
                                              data-vv-as="name"
                                              data-vv-name="user_name"
                                              :error-messages="errors.collect('user_name')"

                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.surname')"
                                              v-model="registration_data.surname"
                                              @keyup="generateEmail"
                                              autocomplete="off"
                                              v-validate="'required'"
                                              data-vv-as="surname"
                                              data-vv-name="user_surname"
                                              :error-messages="errors.collect('user_surname')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_email">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.login_email')"
                                              v-model="registration_data.email"
                                              autocomplete="off"
                                              v-validate="'required|email|checkExistingEmail'"
                                              data-vv-name="user_login_email"
                                              :error-messages="errors.collect('user_login_email')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col v-bind="col_email">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.private_email')"
                                              v-model="seller_data.privateEmail"
                                              autocomplete="off"
                                              v-validate="'required|email'"
                                              data-vv-name="user_private_email"
                                              :error-messages="errors.collect('user_private_email')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.tel')"
                                              v-model="seller_data.phone"

                                              v-validate="'required'"
                                              data-vv-name="user_tel"
                                              :error-messages="errors.collect('user_tel')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.certificates')"
                                              v-model="seller_data.certificates"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--PERSONAL DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.personal_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-menu
                            ref="menu_date_of_birth"
                            v-model="modal_date_of_birth"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="seller_data.dateOfBirth"
                                    :label="$t('users.dialog.date_of_birth')"
                                    prepend-icon="event"
                                    readonly
                                    v-on="on"
                                    outlined dense
                                    @click="$validator.validate('user_date_of_birth')"
                                    @focus="$validator.validate('user_date_of_birth')"
                                    color="gold"
                                    v-validate="'required|bornLimit'"
                                    data-vv-name="user_date_of_birth"
                                    :error-messages="errors.collect('user_date_of_birth')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="seller_data.dateOfBirth"
                                @change="saveDateOfBirth"
                                locale="sk"
                                color="gold"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="user_id_number-input"
                                      :label="$t('users.dialog.id_number')"
                                      v-model="seller_data.personalID"
                                      v-validate="'required'"
                                      data-vv-name="user_id_number"
                                      :error-messages="errors.collect('user_id_number')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <!--PERSON DATA-->
                <v-row v-if="isPerson" :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.company_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="dic-input"
                                      :label="$t('users.dialog.dic')"
                                      v-model="seller_data.taxId"

                                      v-validate="!isPerson ? 'required' : ''"
                                      data-vv-name="dic"
                                      :error-messages="errors.collect('dic')"
                        />
                    </v-col>
                </v-row>
                <!--COMPANY DATA-->
                <v-row v-else :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.company_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="user_company_name-input"
                                      :label="$t('users.dialog.company_name')"
                                      v-model="seller_data.companyName"

                                      v-validate="'required'"
                                      data-vv-name="user_company_name"
                                      :error-messages="errors.collect('user_company_name')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="ico-input"
                                      :label="$t('users.dialog.ico')"
                                      v-model="seller_data.companyId"

                                      v-validate="'required'"
                                      data-vv-name="ico"
                                      :error-messages="errors.collect('ico')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="dic-input"
                                      :label="$t('users.dialog.dic')"
                                      v-model="seller_data.taxId"

                                      v-validate="!isPerson ? 'required' : ''"
                                      data-vv-name="dic"
                                      :error-messages="errors.collect('dic')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="ic_dph-input"
                                      :label="$t('users.dialog.ic_dph')"
                                      v-model="seller_data.vatId"
                        />
                    </v-col>
                </v-row>

                <!--ADDRESS-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.address') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="user_street_and_number-input"
                                      :label="$t('users.dialog.street_and_number')"
                                      v-model="seller_data.mainAddress.street"
                                      @keyup="$validator.validate('user_street_and_number')"

                                      v-validate="'required'"
                                      data-vv-name="user_street_and_number"
                                      :error-messages="errors.collect('user_street_and_number')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold"
                                      :label="$t('users.dialog.city')"
                                      v-model="seller_data.mainAddress.city"
                                      @keyup="$validator.validate('user_city')"

                                      v-validate="'required'"
                                      data-vv-name="user_city"
                                      :error-messages="errors.collect('user_city')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold"
                                      :label="$t('users.dialog.psc')"
                                      v-model="seller_data.mainAddress.zip"
                                      @keyup="$validator.validate('user_psc')"

                                      v-validate="'required'"
                                      data-vv-name="user_psc"
                                      :error-messages="errors.collect('user_psc')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-select outlined dense color="gold"
                                  :label="$t('users.dialog.country')"
                                  :items="getCountries"
                                  item-text="name"
                                  v-model="seller_data.mainAddress.country"
                        />
                    </v-col>
                </v-row>

                <!--AGREEMENT DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.agreement_data') }}
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-select outlined dense color="gold"
                                  :label="$t('users.dialog.position')"
                                  :items="getAvailablePositions"
                                  item-text="name"
                                  item-value="id"
                                  v-model="seller_data.position"
                                  :disabled="positionsDisabled && !edit"
                        />
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-dialog
                            ref="dialog_start_collaboration"
                            v-model="modal_start_collaboration"
                            :return-value.sync="seller_data.contractStart"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="seller_data.contractStart"
                                    :label="$t('users.dialog.start_collaboration')"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined dense
                                    color="gold"
                                    :disabled="checkPrivileg && !edit"
                                />
                            </template>
                            <v-date-picker
                                v-model="seller_data.contractStart"
                                scrollable
                                :first-day-of-week="1"
                                locale="sk"
                                color="gold"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="gold"
                                    @click="modal_start_collaboration = false"
                                >
                                    {{ $t('common.cancel') }}
                                </v-btn>
                                <v-btn
                                    text
                                    color="gold"
                                    @click="$refs.dialog_start_collaboration.save(seller_data.contractStart)"
                                >
                                    {{ $t('common.ok') }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold"
                                      :label="$t('users.dialog.iban')"
                                      v-model="seller_data.bankAccount"
                        />
                    </v-col>

                </v-row>

                <!--BOSS-->
                <v-row v-if="seller_data.boss !== null" :class="{ 'rgg-grey' : isDark }"
                       class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('sellers.dialog.boss') }}
                    </v-col>
                    <v-col cols="12">
                        <v-card flat outlined>
                            <v-card-text>
                            <span class="headline">
                                        {{ seller_data.boss.name + " " + seller_data.boss.surname }}
                                        <span class="gold--text" v-if="seller_data.boss.companyName !== ''">
                                            - {{ seller_data.boss.companyName }}
                                        </span>
                                    </span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col v-if="checkPrivileg" cols="12">
                        <v-btn class="gold lighten-1 white--text" depressed small
                               @click="dialog_sellers = true">
                            <v-icon left>mdi-account-tie</v-icon>
                            <span>{{ $t('users.dialog.change_boss') }}</span>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row v-if="checkPrivileg && edit" :class="{ 'rgg-grey' : isDark }"
                       class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.advanced_menu') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-switch
                            v-model="seller_data.companyPartner"
                            color="gold"
                            :label="$t('users.dialog.company_partner')"
                        ></v-switch>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-switch
                            v-model="seller_data.publicProfile"
                            color="gold"
                            :label="$t('users.dialog.public_profile')"
                        ></v-switch>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-switch
                            v-model="seller_data.canPurchasePackage"
                            color="gold"
                            :label="$t('users.dialog.can_purchase_package')"
                        ></v-switch>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-switch
                            v-model="seller_data.canUsePurchaseDiscount"
                            color="gold"
                            :label="$t('users.dialog.can_use_purchase_discount')"
                        ></v-switch>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-switch
                            v-model="seller_data.canSellPackage"
                            color="gold"
                            :label="$t('users.dialog.can_sell_package')"
                        ></v-switch>
                    </v-col>
                    <!--<v-col v-bind="col_default">
                        <v-switch
                            v-model="seller_data.canSellProductT5"
                            color="gold"
                            :label="$t('users.dialog.can_sell_product_t5')"
                        ></v-switch>
                    </v-col>-->

                    <v-col v-bind="col_default">
                        <v-switch
                            v-model="seller_data.canSellProductT5ForFree"
                            color="gold"
                            :label="$t('users.dialog.can_sell_product_t5_for_free')"
                        ></v-switch>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-switch
                            v-model="seller_data.unlimitedActivity"
                            color="gold"
                            :label="$t('users.dialog.unlimited_activity')"
                        ></v-switch>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>

        <!--READER-->
        <v-container v-else fluid>
            <div v-if="data_ready">
                <!--BASIC DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.basic_data') }}
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.id') }}</v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ seller_data.id }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.type')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">
                                        {{ person_types.find(element => element.id === seller_data.type).name }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.title')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ g_isDefined(seller_data.degree) }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.name')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ g_isDefined(seller_data.name) }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.surname')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ g_isDefined(seller_data.surname) }}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_email">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.login_email')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ g_isDefined(seller_data.email) }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_email">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.private_email')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{
                                            g_isDefined(seller_data.privateEmail)
                                        }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.tel')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{ g_isDefined(seller_data.phone) }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-row class="rounded ma-0 reader">
                                    <v-col cols="12" class="caption gold--text py-0">{{
                                            $t('users.dialog.certificates')
                                        }}
                                    </v-col>
                                    <v-col cols="12" class="body-1 py-0">{{
                                            g_isDefined(seller_data.certificates)
                                        }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--PERSONAL DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.personal_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{
                                    $t('users.dialog.date_of_birth')
                                }}
                            </v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_convertDate(seller_data.dateOfBirth) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.id_number') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ seller_data.personalID }}</v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--COMPANY DATA-->
                <v-row v-if="isPerson" :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.company_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.dic') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_isDefined(seller_data.taxId) }}</v-col>
                        </v-row>
                    </v-col>

                </v-row>
                <v-row v-else :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.company_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{
                                    $t('users.dialog.company_name')
                                }}
                            </v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_isDefined(seller_data.companyName) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.ico') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_isDefined(seller_data.companyId) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.dic') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_isDefined(seller_data.taxId) }}</v-col>
                        </v-row>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.ic_dph') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_isDefined(seller_data.vatId) }}</v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--ADDRESS-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.address') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{
                                    $t('users.dialog.street_and_number')
                                }}
                            </v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_isDefined(seller_data.mainAddress.street)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.city') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_isDefined(seller_data.mainAddress.city) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.psc') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_isDefined(seller_data.mainAddress.zip) }}</v-col>
                        </v-row>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.country') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_isDefined(seller_data.mainAddress.country)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--AGREEMENT DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.agreement_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.position') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">
                                {{ positions.find(element => element.id === seller_data.position).name }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{
                                    $t('users.dialog.start_collaboration')
                                }}
                            </v-col>
                            <v-col cols="12" class="body-1 py-0">{{ g_convertDate(seller_data.contractStart) }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-row class="rounded ma-0 reader">
                            <v-col cols="12" class="caption gold--text py-0">{{ $t('users.dialog.iban') }}</v-col>
                            <v-col cols="12" class="body-1 py-0">{{
                                    g_parseBankAccount(seller_data.bankAccount)
                                }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--BOSS-->
                <v-row v-if="seller_data.boss !== null" :class="{ 'rgg-grey' : isDark }"
                       class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('sellers.dialog.boss') }}
                    </v-col>
                    <v-col cols="12">
                        <v-card flat outlined>
                            <v-card-text>
                            <span class="headline">
                                        {{ seller_data.boss.name + " " + seller_data.boss.surname }}
                                        <span class="gold--text" v-if="seller_data.boss.companyName !== ''">
                                            - {{ seller_data.boss.companyName }}
                                        </span>
                                    </span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-container>

        <!--BOTTOM BUTTONS-->
        <v-container fluid>
            <v-row align="center" justify="center" class="mt-4">
                <v-col cols="12" align="center" v-if="checkPrivileg && !edit">
                    <v-btn class="gold lighten-1 white--text mr-1" depressed small @click="submit">
                        {{ $t('common.registration') }}
                    </v-btn>
                    <v-btn class="white lighten-1 gold--text mr-1" depressed small outlined
                           @click="closeDialog">{{ $t('common.cancel') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-if="seller_data.boss !== null" v-model="dialog_sellers" persistent scrollable fullscreen>
            <v-card flat v-if="dialog_sellers">
                <v-toolbar fixed dark dense color="gold" style="z-index: 10; flex: unset;">
                    <v-btn icon dark @click="closeSellerSelectDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        <span>{{ $t('users.dialog.choice_boss') }}</span>
                    </v-toolbar-title>

                </v-toolbar>
                <v-card-text class="pt-0 px-0">
                    <DataSelect :data_view_name="'sellers_list_select'"/>
                </v-card-text>
            </v-card>
        </v-dialog>

    </section>
</template>

<script>
import DataSelect from "@/components/DataSelect";
import CareerWidget from "@/components/CareerWidget";
import countries from "@/assets/countries";
import AuthMixin from "@/services/auth/auth_mixin";
import Permissions from "@/services/auth/permissions";
import {activation, checkEmail, resetPassword} from "@/api/auth";
import {
    createCompanyEmail,
    createSeller,
    recomputeHierarchy,
    sendRegistrationEmail,
    sendStatusActivityMail,
    updateSeller
} from "@/api/sellers";
import AdvancedMenu from "@/components/AdvancedMenu";
import {Utils} from "@/services/utils";

export default {
    name: "SellerDialog",
    components: {
        DataSelect,
        CareerWidget,
        AdvancedMenu
    },
    props: {
        edit: Boolean,
        id: undefined,
        card_item: undefined
    },
    mixins: [AuthMixin],
    data: () => ({
        dialog_sellers: false,
        data_ready: false,

        person_types: [
            {id: 'PERSON', name: "Osoba"},
            {id: 'COMPANY', name: "Firma"},
            {id: 'SELF_EMPLOYED', name: "SZČO"}
        ],
        positions: [
            {id: 'P1', name: "P1 - VIP Member"},
            {id: 'P2', name: "P2 - Consultant"},
            {id: 'P3', name: "P3 - Senior Consultant"},
            {id: 'P4', name: "P4 - Team Manager"},
            {id: 'P5', name: "P5 - Regional Manager"},
            {id: 'P6', name: "P6 - Division Director"},
            {id: 'P7', name: "P7 - Executive Director"},
            {id: 'GD', name: "GD - General Director"},
            {id: 'CD', name: "CD - Company Director"},
            {id: 'RGGP', name: "RGGP - RGG Partners"},
            {id: 'RGGC', name: "RGGC - RGG Company"}
        ],

        seller_data: {},
        registration_data: {
            name: '',
            surname: '',
            email: ''
        },
        modal_date_of_birth: false,
        modal_start_collaboration: false,
        isPerson: true,

        col_default: {cols: 12, sm: 6, md: 4, lg: 3},
        col_email: {cols: 12, sm: 12, md: 6, lg: 6},

        seller_active: false,

        amenu: []
    }),

    watch: {
        modal_date_of_birth(val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },

        '$vuetify.breakpoint.width': function () {
            this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title})
        },
    },

    computed: {
        isDark() {
            return this.$vuetify.theme.dark;
        },

        toolbar_title() {
            if (this.edit) {
                if (this.$vuetify.breakpoint.width < 451) {
                    return this.id;
                } else {
                    return this.seller_data.name + " " + this.seller_data.surname + " - " + this.id;
                }
            } else {
                return this.edit
            }
        },

        getSelectedSeller() {
            return this.$store.getters.getSelectedSeller;
        },

        getCountries() {
            return countries.country_codes;
        },

        canSeeCareer() {
            return !this.checkPermission(Permissions.SUPPORTER);
        },

        checkPrivileg() {
            if (this.checkPermission(Permissions.ADMINISTRATOR) || this.checkPermission(Permissions.SUPPORTER)) {
                return true;
            }

            return !this.edit;
        },

        getItem() {
            let seller_data = {};
            seller_data.active = this.seller_data.active
            seller_data.status = this.seller_data.status;
            seller_data.position = this.seller_data.position;

            return seller_data;
        },

        getAvailablePositions() {
            if (this.checkPermission(Permissions.ADMINISTRATOR) || this.edit) {
                return this.positions
            }

            let positions = [this.positions[0]]
            let userPosition = this.$store.getters.getUserPosition
            for (let i = 1; i < this.positions.length; i++) {
                if (this.positions[i].name !== userPosition) {
                    positions.push(this.positions[i])
                } else {
                    break
                }
            }

            return positions
        },

        positionsDisabled() {
            if (this.checkPermission(Permissions.ADMINISTRATOR)) {
                return false
            }

            let userPosition = this.$store.getters.getUserPosition
            return this.edit || userPosition.startsWith('P1') || userPosition.startsWith('P2') || userPosition.startsWith('P3') || userPosition.startsWith('P4')
        },

        advancedMenu() {
            return [
                {
                    label: 'send_registration_email', click() {
                        this.$root.$emit('overlay', true);
                        sendRegistrationEmail(this.seller_data.id).then(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: 'seller.send_registration_email_notify'
                            })
                            this.closeDialog();
                        }).catch(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'error',
                                icon: 'info',
                                msg: 'notification.server_error'
                            });
                        })
                    }
                },
                {
                    label: 'create_company_email', click() {
                        this.$root.$emit('overlay', true);
                        createCompanyEmail(this.seller_data.id).then(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: 'seller.create_company_email_notify'
                            })
                            this.closeDialog();
                        }).catch(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'error',
                                icon: 'info',
                                msg: 'notification.server_error'
                            });
                        })
                    }
                },
                {
                    disable_item: !this.seller_data.active,
                    label: 'reset_password', click() {
                        this.$root.$emit('overlay', true);
                        resetPassword(this.seller_data.email).then(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: 'seller.reset_password_notify'
                            })
                            this.closeDialog();
                        }).catch(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'error',
                                icon: 'info',
                                msg: 'notification.server_error'
                            });
                        })
                    }
                },
                {
                    disable_item: !this.seller_data.active,
                    label: 'deactivate', click() {
                        this.$root.$emit('overlay', true);
                        activation(this.seller_data.email).then(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: this.seller_data.active ? 'seller.deactivate_notify' : 'seller.activate_notify'
                            })
                            this.seller_data.active = !this.seller_data.active
                            this.closeDialog();
                            this.$root.$emit('reload');
                        }).catch(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'error',
                                icon: 'info',
                                msg: 'notification.server_error'
                            });
                        })
                    }
                },
                {
                    disable_item: this.seller_data.active,
                    label: 'activate', click() {
                        this.$root.$emit('overlay', true);
                        activation(this.seller_data.email).then(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: this.seller_data.active ? 'seller.deactivate_notify' : 'seller.activate_notify'
                            })
                            this.seller_data.active = !this.seller_data.active
                            this.closeDialog();
                            this.$root.$emit('reload');
                        }).catch(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'error',
                                icon: 'info',
                                msg: 'notification.server_error'
                            });
                        })
                    }
                },
                {
                    label: 'send_status_activity_mail', click() {
                        this.$root.$emit('overlay', true);
                        sendStatusActivityMail(this.seller_data.id).then(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: 'seller.status_activity_mail_notify'
                            })
                            this.closeDialog();
                        }).catch(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'error',
                                icon: 'info',
                                msg: 'notification.server_error'
                            });
                        })
                    }
                },
                {
                    label: 'recompute_hierarchy', click() {
                        this.$root.$emit('overlay', true);
                        recomputeHierarchy(this.seller_data.id).then(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'success',
                                icon: 'update',
                                msg: 'seller.recompute_hierarchy_notify'
                            })
                            this.closeDialog();
                        }).catch(() => {
                            this.$root.$emit('overlay', false);
                            this.$root.$emit('notification', {
                                type: 'error',
                                icon: 'info',
                                msg: 'notification.server_error'
                            });
                        })
                    }
                },
            ]
        }
    },

    methods: {
        closeSellerSelectDialog() {
            this.dialog_sellers = false
            this.$root.$emit('reload')
        },

        saveDateOfBirth() {
            this.$refs.menu_date_of_birth.save(this.seller_data.dateOfBirth)
            setTimeout(() => (this.$validator.validate('user_date_of_birth')))
        },

        handleClick(index) {
            this.advancedMenu[index].click.call(this);
        },

        generateEmail() {
            let email;
            email = this.registration_data.name + "." + this.registration_data.surname + '@royalgoldengroup.sk';

            this.registration_data.email = this.g_removeDiacritics(email.split(" ").join("").toLowerCase());
        },

        checkType() {
            this.isPerson = this.seller_data.type === 'PERSON';
            this.validatorRefresh();
        },

        validatorRefresh() {

            //let vee = this.$validator;
            this.$validator.pause();

            this.$nextTick(() => {
                this.$validator.errors.clear();
                this.$validator.fields.items.forEach(f => f.reset());
                this.$validator.fields.items.forEach(f => this.errors.remove(f));
                this.$validator.resume();
            });
        },

        callback(e) {
            this.$parent.$emit('action', {func: e, data: this.id});
        },

        callbackFromCreateMode(_action) {
            this.$parent.$emit('call', {func: _action, data: this.seller_data});
        },

        closeDialog() {
            if (this.edit) {
                this.$parent.$emit('action', {func: 'close', data: null});
            } else {
                this.$parent.$emit('call', {func: 'close', data: null});
            }
        },

        reset() {
            this.seller_data = {};
        },

        submit() {
            this.$validator.validateAll().then(() => {
                this.$root.$emit('overlay', true);
                if (!this.$validator.errors.any()) {
                    this.save()
                } else {
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.form_error'
                    });
                }
            })
        },

        selectBoss(_bossID) {
            this.$store.dispatch('getSeller', _bossID)
                .then(() => {
                    this.seller_data.boss = this.getSelectedSeller;
                    this.$root.$emit('overlay', false);
                    this.dialog_sellers = false;
                    this.$root.$emit('reload')
                }).catch()
        },

        save() {
            this.$root.$emit('overlay', true);

            let sellerForm = {
                email: this.registration_data.email,

                name: this.registration_data.name,
                surname: this.registration_data.surname,
                degree: this.seller_data.degree,

                dateOfBirth: this.seller_data.dateOfBirth,
                personalID: this.seller_data.personalID,

                type: this.seller_data.type,

                companyId: this.seller_data.companyId,
                companyName: this.seller_data.companyName,
                taxId: this.seller_data.taxId,
                vatId: this.seller_data.vatId,

                phone: this.seller_data.phone,

                mainAddress: {
                    street: this.seller_data.mainAddress.street,
                    city: this.seller_data.mainAddress.city,
                    zip: this.seller_data.mainAddress.zip,
                    country: this.seller_data.mainAddress.country
                },

                bankAccount: this.seller_data.bankAccount,

                bossId: this.seller_data.boss.id,

                position: this.seller_data.position,
                privateEmail: this.seller_data.privateEmail,
                contractStart: this.seller_data.contractStart,
                certificates: this.seller_data.certificates,

                companyPartner: this.edit ? this.seller_data.companyPartner : false,
                publicProfile: this.edit ? this.seller_data.publicProfile : false,
                canPurchasePackage: this.edit ? this.seller_data.canPurchasePackage : false,
                canUsePurchaseDiscount: this.edit ? this.seller_data.canUsePurchaseDiscount : false,
                canSellPackage: this.edit ? this.seller_data.canSellPackage : false,
                canSellProductT5: this.edit ? this.seller_data.canSellProductT5 : false,
                canSellProductT5ForFree: this.edit ? this.seller_data.canSellProductT5ForFree : false,
                unlimitedActivity: this.edit ? this.seller_data.unlimitedActivity : false
            }

            if (this.seller_data.type === 'PERSON') {
                sellerForm.companyId = ''
                sellerForm.companyName = ''
                sellerForm.vatId = ''
            }

            if (this.edit) {
                updateSeller(this.seller_data.id, sellerForm).then(() => {
                    this.$root.$emit('overlay', false);
                    this.closeDialog();
                    this.$root.$emit('reload');
                    this.$root.$emit('notification', {
                        type: 'success',
                        icon: 'update',
                        msg: 'seller.update_notify'
                    })
                })
            } else {
                createSeller(sellerForm).then(() => {
                    this.$root.$emit('overlay', false);
                    this.closeDialog();
                    this.$root.$emit('notification', {
                        type: 'success',
                        icon: 'update',
                        msg: 'seller.create_notify'
                    })
                }).catch((e) => {
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: e
                    });
                })
            }
        },
    },

    created() {
        this.$validator.extend('checkExistingEmail', {
            validate: (value) => {
                return checkEmail(value)
                    .then(response => {
                        if (this.edit) {
                            return true
                        }
                        return !response.data;
                    })
            }
        });

        this.$validator.extend('checkInputs', {
            validate: (value, params) => {
                return this.registration_data[params[0]] !== '' && this.registration_data[params[1]] !== '';
            }
        });

        this.$validator.extend('bornLimit', {
            validate: (value) => {
                return Utils.is18YearsOld(value)
            }
        });

        this.seller_data.mainAddress = {
            city: "",
            country: "Slovensko",
            street: "",
            zip: ""
        };

        this.seller_data.boss = {};
        this.seller_data.position = 'P1';
        this.seller_data.type = 'PERSON';
        this.seller_data.phone = '+421';
        this.seller_data.contractStart = this.g_getDate();
        this.seller_data.status = [];

        this.validatorRefresh();

    },

    mounted() {

        if (this.edit) {
            this.$root.$emit('overlay', true);
            this.$store.dispatch('getSeller', this.id)
                .then(() => {
                    this.seller_data = this.getSelectedSeller;
                    this.registration_data.name = this.seller_data.name;
                    this.registration_data.surname = this.seller_data.surname;
                    this.registration_data.email = this.seller_data.email;
                    this.checkType();
                    this.data_ready = true;
                    this.$root.$emit('overlay', false);
                }).catch()
        } else {
            this.$root.$emit('overlay', true);
            this.$store.dispatch('getSeller', this.$store.getters.getUserId)
                .then(() => {
                    Object.assign(this.seller_data.boss, this.getSelectedSeller);
                    this.$forceUpdate();
                    this.$root.$emit('overlay', false);
                }).catch()
        }

        this.$root.$on('item-seller', (id) => {
            this.selectBoss(id);
        });

        this.seller_active = this.seller_data.active;

        this.$root.$on('am-refresh', () => {
            this.seller_active = this.seller_data.active;
        })

    },
    destroyed() {
        this.$store.dispatch('clearSeller');
        this.$root.$off('item-seller');
        this.$root.$off('am-refresh');
        this.reset();
    },
    updated() {
        this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title});
    }
}
</script>

<style>
.reader {
    background-color: rgba(201, 158, 68, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

#app.theme--dark .reader {
    background-color: rgba(0, 0, 0, 0.1);
}
</style>
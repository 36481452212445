import ApiService from "@/services/api_service";
import {Utils} from "@/services/utils";

export function getCustomers(filter_data, cancel_request) {
    return ApiService.cancellableGet("/customers" + Utils.getFilterUrl(filter_data), cancel_request)
}

export function getCustomer(id) {
    return ApiService.get("/customers/" + id);
}

export function createCustomer(data) {
    return ApiService.post("/customers", data);
}